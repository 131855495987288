import Slick from '../plugins/slick.js';
import Cookie from '../plugins/jquery.cookie.js';

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
  // All pages
  common: {
    init: function() {
      /////
      // JavaScript to be fired on all pages
      /////

      // Sticky Header
      var myElement = document.querySelector(".global-header");
      var headroom  = new Headroom(myElement, {
        "offset": 200,
        "tolerance": 0
      });
      headroom.init();

      // Search
      $('#search-toggle').click(function(e){
        e.preventDefault();
        $('#search-popup').toggleClass('active');
        $('#search-input').focus();
        $('body, html').toggleClass('search-open');
        $('body, html').removeClass('nav-locked');
        $('#mobile-navigation-wrapper').removeClass('active');
        $('#mobile-nav-toggle').removeClass('active');
      });
      $('.close-search').click(function(){
        $('#search-popup').removeClass('active');
        $('body, html').removeClass('search-open');
      });

      // Cookied Popup
      $('.close-cookie-popup').on('click', function(){
        $('.cookied-popup-wrapper').css('display', 'none');
        $.cookie('popup', $('.cookied-popup-wrapper').attr('id'), { path: '/' });
      });

      // Pagination
      $('#pagination').on('click', 'a', function(e){
        e.preventDefault();
        $(this).remove();
        $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
          $('#ajax-posts').append($('#ajax-loader').html());
        });
        $('#pagination').load($(this).attr('href') + ' #pagination>*');
      });

      // Category Filters
      $('.filter-toggle').click(function(e){
        e.preventDefault();
        $('.filter-toggle').removeClass('active');
        $(this).addClass('active');
        $('#ajax-posts').load($(this).attr('href') + ' #ajax-posts>*', function(){
          $('.successstory-post').addClass('reveal reveal200 reveal300');
        });
        $('#pagination').load($(this).attr('href') + ' #pagination>*');
      });

      // Anchor
      $('a').on('click', function(e){
        var $href = $(this).attr('href');
        if ($href.match('^#') && !$(this).hasClass('accordion-toggle') && !$(this).hasClass('close-popup')) {
          e.preventDefault();
          $('html, body').stop().animate({
              scrollTop: $($href).offset().top
          });
        }
      });

      // Accordion
      $('.accordion-toggle').on('click', function(e){
        e.preventDefault();
        $('#' + $(this).data('id')).toggleClass('active');
      });

      // Form Labels
      $('input').on('focus', function(){
        $(this).parent('*').addClass('filled');
      });
      $('input').on('blur', function(){
        if ($(this).val() !== '') {
          $(this).parent('*').addClass('filled');
        } else {
          $(this).parent('*').removeClass('filled');
        }
      });

      // Video popups
      $('a').on('click', function(e){
        var $target = $(this).attr('href');
        if ($target.toLowerCase().indexOf("vimeo") >= 0) {
          e.preventDefault();
          var $n = $target.lastIndexOf('/');
          var $vid = $target.substring($n + 1);
          $('.video-popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
        if ($target.toLowerCase().indexOf("youtu") >= 0 && !$(this).hasClass('social')) {
          e.preventDefault();
          var $y = $target.lastIndexOf('=');
          var $yid = $target.substring($y + 1);
          $('.video-popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
        if ($target.toLowerCase().indexOf("signup-popup") >= 0) {
          e.preventDefault();
          $('.signup-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
      });
      $('.close-popup').on('click', function(){
        $('.video-popup-wrapper').removeClass('active');
        $('.signup-popup-wrapper').removeClass('active');
        $('.popup-wrapper').removeClass('active');
        $('.video-popup-content').html('');
        $('body, html').removeClass('body-noscroll');
      });

      // Mobile Nav
      $('#mobile-nav-toggle').on('click', function(){
        $('body, html').toggleClass('nav-locked');
        $('#mobile-navigation-wrapper').toggleClass('active');
        $('#search-popup').removeClass('active');
        $('body, html').removeClass('search-open');
        $(this).toggleClass('active');
      });

      // Carousel Slick Slider
      $('.carousel-slider').slick();

      // Grid CTA Animation
      function gridcta_animation(){
        $('.grid_cta').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height() - 150) {
            setTimeout(function(){
              $this.find('.col-sm-6:first-child .grid-cta').addClass('reveal');
            }, 100);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(2) .grid-cta').addClass('reveal');
            }, 300);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(3) .grid-cta').addClass('reveal');
            }, 500);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(4) .grid-cta').addClass('reveal');
            }, 700);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(5) .grid-cta').addClass('reveal');
            }, 900);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(6) .grid-cta').addClass('reveal');
            }, 1100);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(7) .grid-cta').addClass('reveal');
            }, 1300);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(8) .grid-cta').addClass('reveal');
            }, 1500);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(9) .grid-cta').addClass('reveal');
            }, 1700);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(10) .grid-cta').addClass('reveal');
            }, 1900);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(11) .grid-cta').addClass('reveal');
            }, 2100);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(12) .grid-cta').addClass('reveal');
            }, 2300);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(13) .grid-cta').addClass('reveal');
            }, 2500);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(14) .grid-cta').addClass('reveal');
            }, 2700);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(15) .grid-cta').addClass('reveal');
            }, 2900);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(16) .grid-cta').addClass('reveal');
            }, 3100);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(17) .grid-cta').addClass('reveal');
            }, 3300);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(18) .grid-cta').addClass('reveal');
            }, 3500);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(19) .grid-cta').addClass('reveal');
            }, 3700);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(20) .grid-cta').addClass('reveal');
            }, 3900);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(21) .grid-cta').addClass('reveal');
            }, 4100);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(22) .grid-cta').addClass('reveal');
            }, 4300);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(23) .grid-cta').addClass('reveal');
            }, 4500);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(24) .grid-cta').addClass('reveal');
            }, 4700);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(25) .grid-cta').addClass('reveal');
            }, 4900);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(26) .grid-cta').addClass('reveal');
            }, 5100);
            setTimeout(function(){
              $this.find('.col-sm-6:nth-child(27) .grid-cta').addClass('reveal');
            }, 5300);
          }
        });
      }

      // News Posts Animation
      function news_animation(){
        $('.latest_news').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height() - 150) {
            setTimeout(function(){
              $this.find('.col-md-4:first-child .news-post').addClass('reveal');
            }, 100);
            setTimeout(function(){
              $this.find('.col-md-4:nth-child(2) .news-post').addClass('reveal');
            }, 300);
            setTimeout(function(){
              $this.find('.col-md-4:nth-child(3) .news-post').addClass('reveal');
            }, 500);
          }
        });
      }

      // Global Animations
      function animations(){
        $('.animate').each(function(){
          var $animatethis = $(this);
          if ($animatethis.offset().top < $(window).scrollTop() + $(window).height() - 100) {
            setTimeout(function(){
              $animatethis.addClass('reveal');
            }, 100);
            setTimeout(function(){
              $animatethis.addClass('reveal200');
            }, 200);
            setTimeout(function(){
              $animatethis.addClass('reveal300');
            }, 300);
            setTimeout(function(){
              $animatethis.addClass('reveal400');
            }, 400);
            setTimeout(function(){
              $animatethis.addClass('reveal500');
            }, 500);
            setTimeout(function(){
              $animatethis.addClass('reveal1000');
            }, 1000);
            setTimeout(function(){
              $animatethis.addClass('reveal1500');
            }, 1500);
            setTimeout(function(){
              $animatethis.addClass('reveal2000');
            }, 2000);
          }
        });
      }

      animations();
      news_animation();
      gridcta_animation();

      // Scroll functions
      $(window).scroll(function(){
        animations();
        news_animation();
        gridcta_animation();
      });

      // Resize for Match-Height and Slick-Slider glitch fix
      $(window).bind('load', function(){
        $(window).trigger('resize');
        $(window).trigger('scroll');
        setTimeout(function(){ $(window).trigger('resize'); $(window).trigger('scroll'); }, 500);
      });
    }
  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page
      $(window).bind('load', function(){
        $('.home-hero').addClass('reveal');
        setTimeout(function(){ $('.home-hero').addClass('reveal2'); }, 200);
        setTimeout(function(){ $('.home-hero').addClass('reveal3'); }, 400);
        setTimeout(function(){ $('.home-hero').addClass('reveal4'); }, 600);
        setTimeout(function(){ $('.home-hero').addClass('reveal5'); }, 800);
      });
    }
  },
  // Search page
  search: {
    init: function() {
      $('#onpage-search-input').focus();
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.